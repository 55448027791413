<pl-header></pl-header>
<main>
  <div id="mainContent" role="main" tabindex="-1">
    <div [hidden]="!clicked" id="div-spinner">
      <h3 class="text-secondary font-weight-lighter text-center">Processing your request.</h3>
      <pl-spinner></pl-spinner>
    </div>
    <div class="row" [hidden]="clicked">
      <div class="col-sm-8 mx-auto">
        <br/>
        <br/>
        <h2>{{listeningPostTitle}}<br /><br /> {{listeningPostSubtitle}}</h2>
        <form [formGroup]="listensForm" id="listen-form" (ngSubmit)="submitForm()">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="visually-hidden" for="comment">Feedback</label>
                <textarea type="text"
                          rows="10"
                          formControlName="comment"
                          class="form-control"
                          required
                          minlength="4"
                          [ngClass]="{'is-invalid': (formRegister.comment.touched && formRegister.comment?.errors) || (submitted && !formRegister.comment?.value), 'hasLength': formRegister.comment.value, 'hasNoLength': !formRegister.comment?.value, 'lifewise': this.lob == 'LifeWise' }"
                          aria-required="true" aria-label="comment" aria-labelledby="comment-label" id="comment"></textarea>
                <div *ngIf="submitted && ((formRegister.comment.errors && formRegister.comment.touched) || !formRegister.comment?.value )" class="invalid-feedback" id="comment-validation-error">
                  <div *ngIf="formRegister.comment?.errors">Your feedback is required for submission.</div>
                </div>
                <span class="zoom-label" id="comment-label">{{commentFieldLabel}}</span>
                <small id="commentHelp" class="form-text text-muted">
                  For privacy purposes, please do not
                  include any member account or medical information. Thank you.
                </small>
              </div>
            </div>
          </div>
          <div *ngIf="!isProvider && !isProducer">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="memberSubmittedName">Name</label>
                  <input class="form-control"
                         id="memberSubmittedName"
                         name="memberSubmittedName"
                         #memberSubmittedName
                         formControlName="memberSubmittedName"
                         [required]="lob=='FEP'"
                         type="text"
                         [ngClass]="{'hasLength': memberSubmittedName?.value,'hasNoLength': !memberSubmittedName?.value,'lifewise': this.lob=='LifeWise','is-invalid': ((formRegister.memberSubmittedName.touched && formRegister.memberSubmittedName?.errors) || (submitted && !formRegister.memberSubmittedName?.value)) && this.lob=='FEP'}">
                  <span class="zoom-label" id="name-label">Name</span>
                  <div *ngIf="submitted && ((formRegister.memberSubmittedName.errors && formRegister.memberSubmittedName.touched) || !formRegister.memberSubmittedName?.value )" class="invalid-feedback" id="name-validation-error">
                    <div *ngIf="formRegister.memberSubmittedName?.errors">Your name is required for submission.</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="memberSubmittedContactInfo">Phone number or email address</label>
                  <input class="form-control"
                         id="memberSubmittedContactInfo"
                         name="memberSubmittedContactInfo"
                         #memberSubmittedContactInfo
                         formControlName="memberSubmittedContactInfo"
                         [required]="lob=='FEP'"
                         type="text"
                         [ngClass]="{'hasLength': memberSubmittedContactInfo?.value,'hasNoLength': !memberSubmittedContactInfo?.value,'lifewise': this.lob=='LifeWise','is-invalid': ((formRegister.memberSubmittedContactInfo.touched && formRegister.memberSubmittedContactInfo?.errors) || (submitted && !formRegister.memberSubmittedContactInfo?.value)) && this.lob=='FEP'}">
                  <span class="zoom-label" id="contact-label">Phone number or email address</span>
                  <div *ngIf="submitted && ((formRegister.memberSubmittedContactInfo.errors && formRegister.memberSubmittedContactInfo.touched) || !formRegister.memberSubmittedContactInfo?.value )" class="invalid-feedback" id="contact-validation-error">
                    <div *ngIf="formRegister.memberSubmittedContactInfo?.errors">Your contact information is required for submission.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isProvider">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="providerOrganization">Provider Organization</label>
                  <input class="form-control" id="providerOrganization" name="providerOrganization" #providerOrganization formControlName="providerOrganization" type="text" [ngClass]="{'hasLength': providerOrganization?.value, 'hasNoLength': !providerOrganization?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="providerOrganization-label">Provider Organization</span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="providerTin">Provider/Organization TIN</label>
                  <input class="form-control" id="providerTin" name="providerTin" #providerTin formControlName="providerTin" type="text" [ngClass]="{'hasLength': providerTin?.value, 'hasNoLength': !providerTin?.value, 'lifewise': this.lob=='LifeWise' }"
                         required
                         minlength="9"
                         maxlength="9"
                         [ngClass]="{'is-invalid': (formRegister.providerTin.touched && formRegister.providerTin?.errors) || (submitted && !formRegister.providerTin?.value), 'hasLength': formRegister.providerTin.value, 'hasNoLength': !formRegister.providerTin?.value, 'lifewise': this.lob == 'LifeWise' }"
                         aria-required="true" aria-label="providerTin" aria-labelledby="providerTin-label">
                  <div *ngIf="submitted && ((formRegister.providerTin.errors && formRegister.providerTin.touched) || !formRegister.providerTin?.value )" class="invalid-feedback" id="providerTin-validation-error">
                    <div *ngIf="formRegister.providerTin?.errors">Provider/Organization TIN is required for submission.</div>
                  </div>
                  <span class="zoom-label" id="providerTin-label">Provider/Organization TIN</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="memberId">Member Id (optional)</label>
                  <input class="form-control" id="memberId" name="memberId" #memberId formControlName="memberId" type="text" [ngClass]="{'hasLength': memberId?.value, 'hasNoLength': !memberId?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="memberId-label">Member Id (optional)</span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="claimNumber">Claim Number (optional)</label>
                  <input class="form-control" id="claimNumber" name="claimNumber" #claimNumber formControlName="claimNumber" type="text" [ngClass]="{'hasLength': claimNumber?.value, 'hasNoLength': !claimNumber?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="claimNumber-label">Claim Number (optional)</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="customerServiceReferenceNumber">Customer Service Reference Number (optional)</label>
                  <input class="form-control" id="customerServiceReferenceNumber" name="customerServiceReferenceNumber" #customerServiceReferenceNumber formControlName="customerServiceReferenceNumber" type="text" [ngClass]="{'hasLength': customerServiceReferenceNumber?.value, 'hasNoLength': !customerServiceReferenceNumber?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="customerServiceReferenceNumber-label">Customer Service Reference Number (optional)</span>
                </div>
              </div>
              <div class="col">
              </div>
            </div>
            <div class="row">
              <div class="col">
                If you would like us to reach out directly, please provide your contact information.
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="providerSubmittedName">Contact Name (optional)</label>
                  <input class="form-control" id="providerSubmittedName" name="providerSubmittedName" #providerSubmittedName formControlName="providerSubmittedName" type="text" [ngClass]="{'hasLength': providerSubmittedName?.value, 'hasNoLength': !providerSubmittedName?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="providerSubmittedName-label">Contact Name (optional)</span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="providerSubmittedContactInfo">Email Address (optional)</label>
                  <input class="form-control" id="providerSubmittedContactInfo" name="providerSubmittedContactInfo" #providerSubmittedContactInfo formControlName="providerSubmittedContactInfo" type="text" [ngClass]="{'hasLength': providerSubmittedContactInfo?.value, 'hasNoLength': !providerSubmittedContactInfo?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="providerSubmittedContactInfo-label">Email Address (optional)</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isProducer">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerOrganization">Producer Organization</label>
                  <input class="form-control" id="producerOrganization" name="producerOrganization" #producerOrganization
                         formControlName="producerOrganization" type="text"
                         [ngClass]="{'hasLength': producerOrganization?.value, 'hasNoLength': !producerOrganization?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="producerOrganization-label">Producer Organization</span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerJobTitle">Job Title/Role</label>
                  <mat-select class="form-control" id="producerJobTitle" name="producerJobTitle" #producerJobTitle
                              formControlName="producerJobTitle"
                              multiple
                              (selectionChange)="producerJobTitle?.value?.length > 0 ? producerJobTitle.close() : noOp()"
                              [ngClass]="{'hasLength': producerJobTitle?.value?.length > 0, 'hasNoLength': !producerJobTitle?.value, 'lifewise': this.lob=='LifeWise' }"
                              aria-label="producerJobTitle" aria-labelledby="producerJobTitle-label">
                    <mat-option *ngFor="let item of producerTitleOptions"
                                [value]="item"
                                [disabled]="producerJobTitle.value?.length > 0 && !producerJobTitle.value?.includes(item)">
                      {{item}}
                    </mat-option>
                  </mat-select>
                    <span class="zoom-label" id="providerTin-label">Job Title/Role</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerBookOfBusiness">Primary Book of Business</label>
                  <mat-select class="form-control" id="producerBookOfBusiness" name="producerBookOfBusiness" #producerBookOfBusiness
                              formControlName="producerBookOfBusiness"
                              multiple
                              [ngClass]="{'hasLength': producerBookOfBusiness?.value?.length > 0 && producerBookOfBusiness?.value.length > 0, 'hasNoLength': !producerBookOfBusiness?.value, 'lifewise': this.lob=='LifeWise' }"
                              aria-label="producerBookOfBusiness" aria-labelledby="producerBookOfBusiness-label">
                    <mat-option *ngFor="let item of producerBookOfBusinessOptions"
                                [value]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                  <span class="zoom-label" id="producerBookOfBusiness-label">Primary Book of Business</span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerMarketSegment">Market Segment</label>
                  <mat-select class="form-control" id="producerMarketSegment" name="producerMarketSegment" #producerMarketSegment
                              formControlName="producerMarketSegment"
                              multiple
                              [ngClass]="{'hasLength': producerMarketSegment?.value?.length > 0, 'hasNoLength': !producerMarketSegment?.value, 'lifewise': this.lob=='LifeWise' }"
                              aria-label="producerMarketSegment" aria-labelledby="producerMarketSegment-label">
                    <mat-option *ngFor="let item of producerMarketSegmentOptions"
                                [value]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                  <span class="zoom-label" id="producerMarketSegment-label">Market Segment</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerRegion">My feedback is specific to group business in:</label>
                  <mat-select class="form-control" id="producerRegion" name="producerRegion" #producerRegion
                              formControlName="producerRegion"
                              multiple
                              [ngClass]="{'hasLength': producerRegion?.value?.length > 0, 'hasNoLength': !producerRegion?.value, 'lifewise': this.lob=='LifeWise' }"
                              aria-label="producerRegion" aria-labelledby="producerRegion-label">
                    <mat-option *ngFor="let item of producerRegionOptions"
                                [value]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                  <span class="zoom-label" id="producerRegion-label">My feedback is specific to group business in:</span>
                </div>
              </div>
              <div class="col">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerSubmittedName">Contact Name</label>
                  <input class="form-control" id="producerSubmittedName" name="producerSubmittedName" #producerSubmittedName
                         required
                         formControlName="producerSubmittedName" type="text"
                         [ngClass]="{'is-invalid': (formRegister.producerSubmittedName.touched && formRegister.producerSubmittedName?.errors) || (submitted && !formRegister.producerSubmittedName?.value), 'hasLength': producerSubmittedName?.value, 'hasNoLength': !producerSubmittedName?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="producerSubmittedName-label">Contact Name</span>
                  <div *ngIf="submitted && ((formRegister.producerSubmittedName.errors && formRegister.producerSubmittedName.touched) || !formRegister.producerSubmittedName?.value)"
                       class="invalid-feedback"
                       id="producerSubmittedName-validation-error">
                    <div>Your name is required for submission.</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="visually-hidden" for="producerSubmittedContactInfo">Email Address/Phone Number </label>
                  <input class="form-control" id="producerSubmittedContactInfo" name="producerSubmittedContactInfo" #producerSubmittedContactInfo
                         required
                         formControlName="producerSubmittedContactInfo" type="text"
                         [ngClass]="{'is-invalid': (formRegister.producerSubmittedContactInfo.touched && formRegister.producerSubmittedContactInfo?.errors) || (submitted && !formRegister.producerSubmittedContactInfo?.value), 'hasLength': producerSubmittedContactInfo?.value, 'hasNoLength': !producerSubmittedContactInfo?.value, 'lifewise': this.lob=='LifeWise' }">
                  <span class="zoom-label" id="producerSubmittedContactInfo-label">Email Address/Phone Number</span>
                  <div class="invalid-feedback"
                       id="producerSubmittedContactInfo-validation-error"
                       *ngIf="submitted && ((formRegister.producerSubmittedContactInfo.errors && formRegister.producerSubmittedContactInfo.touched) || !formRegister.producerSubmittedContactInfo?.value)">
                    Your contact information is required for submission.
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <br />
          <div class="float-right">
            <button id="btn-submit" class="btn mr-1" [disabled]="clicked" [ngClass]="isHmo ? 'btn-primary1' : 'btn-primary'">Submit Feedback</button>
          </div>
          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
  </div>
  <pl-footer></pl-footer>
</main>

