import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { FeedbackService } from '../services/feedback.service';
import { Comment } from '../shared/models';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Constants } from "../shared/constants";
import { ListeningPostSettings } from '../listening-post-settings';

@Component({
  selector: 'pl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false
})

export class HomeComponent implements OnInit {

  private paramsRedirect: string;
  private paramsAppName: string;
  private paramsAppOs: string;
  private paramsAppVersion: string;
  private paramsAppDevice: string;
  private paramsPremeraId: string;

  clicked: boolean;
  listensForm: FormGroup;
  lob: string;
  submitted = false;
  isHmo = false;
  isAlaska = false;
  isProvider = false;
  isFep = false;
  isProducer = false;
  listeningPostTitle: string;
  listeningPostSubtitle: string;
  commentFieldLabel: string;

  producerTitleOptions: string[]; 
  producerBookOfBusinessOptions: string[]; 
  producerMarketSegmentOptions: string[]; 
  producerRegionOptions: string[];

  constructor(
    private formBuilder: FormBuilder,
    private feedbackService: FeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.isProvider = this.isProviderSurveyType();
    this.lob = ListeningPostSettings.lineOfBusiness;
    this.isHmo = ListeningPostSettings.brandingType === Constants.brandTypePremeraHMO;
    this.isAlaska = ListeningPostSettings.brandingType === Constants.brandTypePremeraAlaska;
    this.isFep = ListeningPostSettings.brandingType === Constants.brandTypePremeraFep;
    this.isProducer = ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProducer;
    if (this.isProducer) {
      this.producerTitleOptions = [
        "Producer Consultant",
        "Account Executive",
        "Analyst",
        "Other"
      ];
      this.producerBookOfBusinessOptions = [
        "Fully Insured",
        "OptiFlex",
        "Self Funded",
        "Other"
      ];
      this.producerMarketSegmentOptions = [
        "Small Group",
        "Merit",
        "Large Group",
        "Med Supp",
        "Association",
        "Nationals",
        "Other"
      ];
      this.producerRegionOptions = [
        "WA",
        "AK"
      ];
    }


    this.buildForm();

    this.listeningPostTitle = this.getTitleText();

    this.listeningPostSubtitle = this.getSubtitleText();
    this.commentFieldLabel = this.isProvider
      ? ""
      : "Please tell us!";

    this.submitted = false;
    this.clicked = false;
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.readRouterParams(params);
      this.redirectToInvalidPage();
    });
  }

  private getSubtitleText(): string {
    return this.isProvider
      ? "We'd like to hear your feedback."
      : "We'd like to hear from you!";
  }

  private getTitleText(): string {
    if (this.isProvider) return `How was your recent provider experience working with ${this.lob} (customer service, credentialing, Availity tools, resources, etc.)?`;
    if (this.isFep) return "How was your recent experience with the Blue Cross and Blue Shield Federal Employee Program?";
    return `How was your recent experience with ${this.lob}?`;
  }

  async submitForm() {
    this.submitted = true;

    if (this.listensForm.invalid)
      return;

    this.clicked = true;
    let listeningPostSurvey = this.createListeningPostFeedback();
    if (listeningPostSurvey.comment.length < 4) {
      this.goToThankYou();
      return;
    }

    this.feedbackService.addComment(listeningPostSurvey).subscribe({
      next: () => {
        this.goToThankYou();
      },
      error: () => {
        this.goToThankYou();
      }
    });
  }

  private goToThankYou(): void {
    this.router.navigate([`/${Constants.routeThankYou}`]);
  }

  get formRegister() {
    return this.listensForm.controls;
  }

  buildForm() {
    if (this.isProviderSurveyType())
      this.buildProviderForm();
    else if (this.isProducer)
      this.buildProducerForm();    
    else
      this.buildMemberForm();
  }

  noOp(): void {
    //do nothing
  }

  private createListeningPostFeedback(): Comment {
    const listeningPostSurvey = new Comment();
    if (this.isProvider)
      this.setProviderFormFields(listeningPostSurvey);
    else if (this.isProducer)
      this.setProducerFormFields(listeningPostSurvey);
    else
      this.setMemberFormFields(listeningPostSurvey);

    this.setMobileFields(listeningPostSurvey);
    this.setSharedFields(listeningPostSurvey);

    return listeningPostSurvey;
  }

  private setSharedFields(listeningPostSurvey: Comment): void {
    listeningPostSurvey.comment = this.commentControl?.value;
    listeningPostSurvey.businessUnit = this.lob;
    listeningPostSurvey.surveyType = ListeningPostSettings.surveyType;
    listeningPostSurvey.isHmo = this.isHmo;
    listeningPostSurvey.isAlaska = this.isAlaska;
    listeningPostSurvey.premeraId = this.paramsPremeraId;
  }

  private setMobileFields(listeningPostSurvey: Comment): void {
    listeningPostSurvey.redirectSource = this.paramsRedirect;
    listeningPostSurvey.appName = this.paramsAppName;
    listeningPostSurvey.device = this.paramsAppDevice;
    listeningPostSurvey.operatingSystem = this.paramsAppOs;
    listeningPostSurvey.appVersion = this.paramsAppVersion;
  }

  private setMemberFormFields(listeningPostSurvey: Comment): void {
    listeningPostSurvey.memberSubmittedName = this.memberSubmittedNameControl?.value;
    listeningPostSurvey.memberSubmittedContactInfo = this.memberSubmittedContactInfoControl?.value;
  }

  private setProviderFormFields(listeningPostSurvey: Comment): void {
    listeningPostSurvey.providerOrganization = this.providerOrganizationControl?.value;
    listeningPostSurvey.providerSubmittedContactInfo = this.providerSubmittedContactInfoControl?.value;
    listeningPostSurvey.providerTin = this.providerTinControl?.value;
    listeningPostSurvey.providerSubmittedName = this.providerSubmittedNameControl?.value;
    listeningPostSurvey.memberId = this.memberIdControl?.value;
    listeningPostSurvey.claimNumber = this.claimNumberControl?.value;
    listeningPostSurvey.customerServiceReferenceNumber = this.customerServiceReferenceNumberControl?.value;
  }

  private setProducerFormFields(listeningPostSurvey: Comment): void {
    listeningPostSurvey.producerOrganization = this.producerOrganizationControl?.value;
    listeningPostSurvey.producerJobTitle = this.getSingleOptionFromMultiSelect(this.producerJobTitleControl);
    listeningPostSurvey.producerBookOfBusiness = this.getMultiValuedOptionFromMultiSelect(this.producerBookOfBusinessControl);
    listeningPostSurvey.producerMarketSegment = this.getMultiValuedOptionFromMultiSelect(this.producerMarketSegmentControl);
    listeningPostSurvey.producerRegion = this.getMultiValuedOptionFromMultiSelect(this.producerRegionControl);
    listeningPostSurvey.producerSubmittedName = this.producerSubmittedNameControl?.value;
    listeningPostSurvey.producerSubmittedContactInfo = this.producerSubmittedContactInfoControl?.value;
  }

  private getMultiValuedOptionFromMultiSelect(control: AbstractControl): string {
    return control?.value?.join(" | ") ?? null;
  }

  private getSingleOptionFromMultiSelect(control: AbstractControl): string {
    return control?.value?.length > 0 ? control.value[0] : null;
  }

  private buildMemberForm(): void {
    this.listensForm = this.formBuilder.group({
      memberSubmittedName: [''],
      memberSubmittedContactInfo: [''],
      comment: ['']
    });
  }

  private buildProviderForm(): void {
    this.listensForm = this.formBuilder.group({
      providerSubmittedContactInfo: [''],
      providerOrganization: [''],
      providerTin: [''],
      comment: [''],
      providerSubmittedName: [''],
      memberId: [''],
      claimNumber: [''],
      customerServiceReferenceNumber: ['']
    });
  }

  private buildProducerForm(): void {
    this.listensForm = this.formBuilder.group({
      comment: [null],
      producerOrganization: [null],
      producerJobTitle: [null],
      producerBookOfBusiness: [null],
      producerMarketSegment: [null],
      producerRegion: [null],
      producerSubmittedName: [null],
      producerSubmittedContactInfo: [null]
    });
  }

  private redirectToInvalidPage() {
    const redirect = this.paramsRedirect?.toUpperCase();

    if (redirect !== "MOBILE" && this.paramsRedirect != null) {
      this.router.navigate([`/${Constants.routeInvalidRoute}`]);
      return;
    }
  }

  private readRouterParams(params: ParamMap): void {
    this.paramsRedirect = this.readRouteParamCaseInsensitive(params, "redirect");
    this.paramsAppName = this.readRouteParamCaseInsensitive(params, "name");
    this.paramsAppOs = this.readRouteParamCaseInsensitive(params, "os");
    this.paramsAppVersion = this.readRouteParamCaseInsensitive(params, "version");
    this.paramsAppDevice = this.readRouteParamCaseInsensitive(params, "device");
    this.paramsPremeraId = this.readRouteParamCaseInsensitive(params, "event");
  }

  private readRouteParamCaseInsensitive(params: ParamMap, name: string): string {
    let key = params?.keys?.find(x => x?.toLowerCase() == name.toLowerCase())
    return key ? params?.get(key) : null;
  }

  private isProviderSurveyType(): boolean {
    return ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProvider
      || ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProviderGroup
      || ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProviderStudent;
  }

  private get providerOrganizationControl(): AbstractControl {
    return this.listensForm.controls["providerOrganization"];
  }

  private get providerSubmittedContactInfoControl(): AbstractControl {
    return this.listensForm.controls["providerSubmittedContactInfo"];
  }

  private get providerTinControl(): AbstractControl {
    return this.listensForm.controls["providerTin"];
  }

  private get providerSubmittedNameControl(): AbstractControl {
    return this.listensForm.controls["providerSubmittedName"];
  }

  private get memberSubmittedNameControl(): AbstractControl {
    return this.listensForm.controls["memberSubmittedName"];
  }

  private get memberSubmittedContactInfoControl(): AbstractControl {
    return this.listensForm.controls["memberSubmittedContactInfo"];
  }

  private get commentControl(): AbstractControl {
    return this.listensForm.controls["comment"];
  }

  private get memberIdControl(): AbstractControl {
    return this.listensForm.controls["memberId"];
  }

  private get claimNumberControl(): AbstractControl {
    return this.listensForm.controls["claimNumber"];
  }

  private get customerServiceReferenceNumberControl(): AbstractControl {
    return this.listensForm.controls["customerServiceReferenceNumber"];
  }

  private get producerOrganizationControl(): AbstractControl {
    return this.listensForm.controls["producerOrganization"];
  }

  private get producerJobTitleControl(): AbstractControl {
    return this.listensForm.controls["producerJobTitle"];
  }

  private get producerBookOfBusinessControl(): AbstractControl {
    return this.listensForm.controls["producerBookOfBusiness"];
  }

  private get producerMarketSegmentControl(): AbstractControl {
    return this.listensForm.controls["producerMarketSegment"];
  }

  private get producerRegionControl(): AbstractControl {
    return this.listensForm.controls["producerRegion"];
  }

  private get producerSubmittedNameControl(): AbstractControl {
    return this.listensForm.controls["producerSubmittedName"];
  }

  private get producerSubmittedContactInfoControl(): AbstractControl {
    return this.listensForm.controls["producerSubmittedContactInfo"];
  }
 
}
