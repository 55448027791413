import { SupportedLanguage } from "../../shared/models";
import { ListeningPostFooterContentBase } from "./listening-post-footer-content-base";

export class PremeraBlueCrossFooterContent extends ListeningPostFooterContentBase {

  //PBC Group plans, Medicare Supplement, or when Individual & Family plans also share the site
  private documentLink = "https://www.premera.com/documents/037397.pdf";
  private entityName = "Premera Blue Cross";
  private federal = "Federal and Washington state civil rights laws";

  pdfUrl(): string {
    return this.documentLink;
  }

  legalEntityName(): string {
    return this.entityName;
  }

  federalLawsBit(): string {
    return this.federal;
  }

  languageSupport(): SupportedLanguage[] {
    return [
      this.spanish(),
      this.chinese(),
      this.vietnamese(),
      this.korean(),
      this.russian(),
      this.tagalog(),
      this.ukrainian(),
      this.khmer(),
      this.japanese(),
      this.amharic(),
      this.oromo(),
      this.arabic(),
      this.punjabi(),
      this.german(),
      this.lao(),
      this.haitian(),
      this.french(),
      this.polish(),
      this.portuguese(),
      this.italian(),
      this.farsi()
    ]
  }
}
