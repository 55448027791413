import { Component, OnInit } from '@angular/core';
import { ListeningPostSettings } from '../listening-post-settings';

@Component({
  selector: 'pl-invalid-route',
  templateUrl: './invalid-route.component.html',
  styleUrls: ['./invalid-route.component.scss'],
  standalone: false
})
export class InvalidRouteComponent implements OnInit {

  lob: string;

  ngOnInit() {
    this.lob = ListeningPostSettings.lineOfBusiness;
  }

}
