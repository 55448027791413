<pl-header></pl-header>
<main>
  <div id="mainContent" role="main" tabindex="-1">
    <div class="row">
      <div class="col-sm-8 mx-auto">
        <h1 *ngIf="title">{{title}}</h1>
        <br *ngIf="!title" />
        <h2>Thank you for sharing your experience. We review feedback daily to find ways to improve.</h2>
        <br />
        <br />
      </div>
    </div>
  </div>
  <pl-footer></pl-footer>
</main>
